import AreaInfo from "../components/area/AreaInfo";
import GoogleAd from "../components/GoogleAd";

const style = {
  infosGap: "md:py-4 lg:py-12 flex flex-col items-center gap-24 lg:gap-32 ",
  graphTitle:
    "hidden md:flex justify-between items-center h-48 m-auto pt-12 md:pt-24 lg:pt-32 md:w-640 lg:w-800",
  graphText: "text-right md:text-14 lg:text-16",
  graphSize: ["md:w-270 lg:w-360", "md:w-370 lg:w-400"],
};

const areaList = ["pera", "calida", "karu", "oasis"];
const admin = localStorage.getItem("admin");

export default function DetailPage({
  updateGold,
  preset,
  breakPreset,
  maxSlotAndWeight,
}) {
  const handleUpdateGold = (gold) => {
    updateGold(gold);
  };

  return (
    <div>
      <div className={style.graphTitle}>
        <p className={`${style.graphText} ${style.graphSize[0]}`}>
          총 구매 비용
        </p>
        <p className={`${style.graphText} ${style.graphSize[1]}`}>
          개별 구매 비용(1개당 가격)
        </p>
      </div>
      <div className="flex justify-center gap-32">
        <div className={style.infosGap}>
          {areaList.map((area) => (
            <AreaInfo
              area={area}
              key={area}
              updateGold={handleUpdateGold}
              preset={preset}
              breakPreset={breakPreset}
              maxSlotAndWeight={maxSlotAndWeight}
            />
          ))}
        </div>
        {admin && (
          <GoogleAd
            slot="1234567890"
            style={{ width: "100%", height: "100px" }}
          />
        )}
      </div>
    </div>
  );
}
