import React, { useEffect } from "react";

const GoogleAd = ({ slot, style }) => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("AdSense error", e);
    }
  }, []);

  return (
    <div>
      <ins
        className="adsbygoogle"
        style={{
          display: "block",
          ...style,
        }}
        data-ad-client="ca-pub-1441225633158263"
        data-ad-slot={slot}
        data-ad-format="auto"
      ></ins>
    </div>
  );
};

export default GoogleAd;
