import { useState } from "react";
import Header from "../components/header/Header";
import OptionButton from "../components/option/OptionButton";
import Options from "../components/option/Options";
import DetailPage from "./DetailPage";

const style = {};

export default function MainPage() {
  const [totalGold, setTotalGold] = useState(0);
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [isDurationEnd, setIsDurationEnd] = useState(true);
  const [preset, setPreset] = useState(-1);
  const [slotAndWeight, setSlotAndWeight] = useState([4, 400]);

  const handleOptionOpenClick = () => {
    setIsDurationEnd(false);
    setIsOptionOpen((prev) => !prev);

    setTimeout(() => {
      setIsDurationEnd(true);
    }, 500);
  };

  const handleUpdateGold = (gold) => {
    setTotalGold((prev) => prev + gold);
  };

  const handleBreakPreset = () => {
    setPreset(-1);
  };

  const handleUpdatePreset = (preset) => {
    setPreset(preset);
  };

  const handleUpdateSlotAndWeight = (slotAndWeight) => {
    setSlotAndWeight(slotAndWeight);
  };

  return (
    <>
      <Header totalGold={totalGold} />
      <OptionButton optionOpenClick={handleOptionOpenClick} />
      <Options
        isOpen={isOptionOpen}
        isDurationEnd={isDurationEnd}
        updatePreset={handleUpdatePreset}
        updatePresetSlotAndWeight={handleUpdateSlotAndWeight}
      />
      <DetailPage
        updateGold={handleUpdateGold}
        preset={preset}
        breakPreset={handleBreakPreset}
        maxSlotAndWeight={slotAndWeight}
      />
    </>
  );
}
