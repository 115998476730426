import Logo from "./Logo";

const style = {
  headerSize: "h-80",
  header:
    "h-72 bg-white absolute flex justify-center items-center w-full h-72 border-b border-gray-400 border-solid z-50",
};

export default function Header({ totalGold }) {
  return (
    <div className={style.headerSize}>
      <header className={style.header}>
        <Logo totalGold={totalGold} />
      </header>
    </div>
  );
}
