const style = {
  optionButtonBox:
    "flex justify-center relative top-[-23px] md:top-[-25px] lg:top-[-28px]",
  optionButton:
    "border-1 border-solid border-gray-400 rounded-12 pt-14 md:pt-16 lg:pt-20 px-12 text-12 md:text-14 bg-gray-100 hover:bg-gray-200 active:bg-gray-300",
};

export default function OptionButton({ optionOpenClick }) {
  const handleOptionOpenClick = () => {
    optionOpenClick();
  };

  return (
    <div className={style.optionButtonBox}>
      <button className={style.optionButton} onClick={handleOptionOpenClick}>
        Option
      </button>
    </div>
  );
}
